import Select, { Options } from 'react-select';
import { IOfficesSchedulesIndexed, IResource } from '@gettactic/api';
import { DASHBOARD_TEXTS } from '@/lib/utils-dashboard';

export type FormatOptionLabelProps = {
  type: string;
  value: string;
  label: string;
  name: string;
};

type FilterByUserAndResourceProps = {
  schedules: IOfficesSchedulesIndexed;
  onChange: (query: Options<FormatOptionLabelProps>) => void;
};

export default function FilterByUserAndResource(
  props: FilterByUserAndResourceProps
) {
  const { schedules, onChange } = props;

  const users = Object.keys(schedules.users).map((key) => ({
    type: 'user',
    value: schedules.users[key].id,
    label: `${schedules.users[key].name} (${schedules.users[key].email})`,
    name: schedules.users[key].name
  }));

  const resourcesById: Record<string, IResource> = {};

  schedules.elements.forEach((element) => {
    resourcesById[element.resource.id] = element.resource;
  });

  const resources = Object.keys(resourcesById).map((key) => ({
    type: 'resource',
    value: resourcesById[key].id,
    label: resourcesById[key].name,
    name: resourcesById[key].name
  }));

  const options = [
    {
      label: DASHBOARD_TEXTS.filterByUserAndResource.labelUser,
      options: users
    },
    {
      label: DASHBOARD_TEXTS.filterByUserAndResource.labelResource,
      options: resources
    }
  ];

  const formatOptionLabel = (data: FormatOptionLabelProps) => (
    <div key={data.value}>{data.name}</div>
  );

  return (
    <Select
      className="w-full rounded-md border-gray-300"
      classNamePrefix="react-select"
      placeholder={DASHBOARD_TEXTS.filterByUserAndResource.placeholder}
      options={options}
      onChange={onChange}
      formatOptionLabel={formatOptionLabel}
      isMulti
    />
  );
}
