import { IconDoorExit } from '@tabler/icons-react';
import { format, parseISO } from 'date-fns';
import { ISchedule } from '@gettactic/api';
import { Button, TacticTooltip } from '@/components/basic';
import { ReserveCheckTime } from '@/components/reserve/ReserveCheckin/ReserveCheckTime';
import { memo, useMemo, useState } from 'react';
import { ReservationCheckout } from '../ReservationCheckout';

type Props = {
  schedule?: ISchedule;
  checkinResource: (
    resourceId: string,
    scheduleId: string,
    slotId: string
  ) => Promise<void>;
  checkoutResource: (
    resourceId: string,
    scheduleId: string,
    slotId: string
  ) => Promise<void>;
  loading: boolean;
  isAdminCard?: boolean;
  forUserId?: string;
  isRBFlag?: boolean;
  allowCheckIn?: boolean;
  allowCheckOut?: boolean;
  canRespondCancel?: boolean;
  hasCheckedIn?: boolean;
  hasCheckedOut?: boolean;
  checkInTime?: boolean;
  checkOutTime?: string | null;
  checkInCheckOutDisabled?: boolean;
  earliestCheckInTime: string;
  pendingApproval: boolean;
  endTime: string;
  slotId?: string;
  resourceId: string;
  scheduleId: string;
};

type Schedule = {
  endTime: string;
  pendingApproval: boolean;
  earliestCheckInTime: string;
};

function getTitle(schedule: Schedule, allowCheckIn?: boolean) {
  const start = schedule.earliestCheckInTime;
  const end = schedule.endTime;
  if (schedule.pendingApproval) {
    return `Your reservation is still pending. It needs to be approved by an admin.`;
  }
  if (allowCheckIn) {
    return `Check in time allowed between ${format(parseISO(start.replace('Z', '')), 'h:mm a')} - ${format(
      parseISO(end.replace('Z', '')),
      'h:mm a'
    )}`;
  }
  return `You have already checked in`;
}

const CheckoutTime = memo(({ time }: { time?: string | null }) => {
  return !!time ? (
    <div className="flex items-center text-sm text-gray-400">
      <ReserveCheckTime type="checkout" time={time} />
      <IconDoorExit size={14} />
    </div>
  ) : null;
});

export default function ReserveCheckInButton({
  checkinResource,
  checkoutResource,
  loading,
  slotId,
  resourceId,
  scheduleId,
  allowCheckIn,
  allowCheckOut,
  hasCheckedIn,
  hasCheckedOut,
  checkOutTime,
  earliestCheckInTime,
  pendingApproval,
  endTime
}: Props) {
  const title = getTitle(
    {
      endTime,
      pendingApproval,
      earliestCheckInTime
    },
    allowCheckIn
  );
  const [open, setOpen] = useState<boolean>(false);

  const renderCheckOut = useMemo(() => {
    if (allowCheckIn) {
      return (
        <Button
          variant="primary"
          type="button"
          layout="rounded"
          font="boldPrimary"
          disabled={loading}
          className="w-full lg:w-auto"
          onClick={() => checkinResource(resourceId, scheduleId, slotId ?? '')}
        >
          <TacticTooltip
            reference={<span>Check In</span>}
            stateProps={{ placement: 'left-start' }}
            className="justify-center lg:justify-start"
          >
            <span className="text-base font-normal text-gray-800">{title}</span>
          </TacticTooltip>
        </Button>
      );
    } else if (allowCheckOut) {
      return (
        <Button
          variant="transparentPrimary"
          type="button"
          layout="rounded"
          font="boldPrimaryTransparent"
          className="w-full lg:w-auto"
          disabled={loading}
          onClick={() => setOpen(true)}
        >
          Check Out
        </Button>
      );
    }
    return <CheckoutTime time={checkOutTime} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    slotId,
    checkOutTime,
    hasCheckedOut,
    hasCheckedIn,
    allowCheckIn,
    allowCheckOut,
    title,
    loading,
    setOpen
  ]);

  return (
    <>
      {renderCheckOut}
      <ReservationCheckout
        open={open}
        cancelAction={() => setOpen(false)}
        confirmAction={() =>
          checkoutResource(resourceId, scheduleId, slotId ?? '')
        }
        subTitle={
          <p className="text-tacticGrayBlue-800">
            Are you sure you want to check out?
          </p>
        }
      />
    </>
  );
}
