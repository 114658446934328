import { Disclosure } from '@headlessui/react';
import { parseISO } from 'date-fns';
import React, { useMemo } from 'react';
import { IOfficesSchedulesIndexed, IQuestionnaire } from '@gettactic/api';
import QuestionnaireReminder from '@/components/partial/Cards/Questions/QuestionnaireReminder';
import ReservationCardList from '@/components/partial/Cards/ReservationCard/ReservationCardList';
import ReservationCardListDateTitle from '@/components/partial/Cards/ReservationCard/ReservationCardListDateTitle';
import NotReservations from '@/components/dashboard/reservations/NoReservations';

type Props = {
  embedded?: boolean;
  questionnaireData: IQuestionnaire | null;
  officeId: string | null;
  scheduleMeData: IOfficesSchedulesIndexed | null;
  setOpenReservationsPanel?: React.Dispatch<React.SetStateAction<boolean>>;
};

export function TabConfirmed({
  embedded = false,
  questionnaireData,
  officeId,
  scheduleMeData,
  setOpenReservationsPanel
}: Props) {
  const meSchedulesList = useMemo(() => {
    const all =
      officeId && scheduleMeData && scheduleMeData?.dates
        ? Object.keys(scheduleMeData?.dates)
        : [];
    all.sort();
    return all;
  }, [officeId, scheduleMeData]);
  return (
    <>
      {questionnaireData &&
      questionnaireData.is_mandatory &&
      questionnaireData.can_respond &&
      !questionnaireData.is_passed ? (
        <QuestionnaireReminder
          embedded={embedded}
          is_passed={questionnaireData.is_passed}
          can_update={questionnaireData.can_update}
          maximum_minutes_grace_period={
            questionnaireData.maximum_minutes_grace_period
          }
        />
      ) : null}
      {meSchedulesList.length && officeId && scheduleMeData ? (
        <>
          {meSchedulesList.map((date) => (
            <div className="ml-1 mr-1" key={date}>
              <Disclosure defaultOpen={true}>
                {({ open }) => (
                  <>
                    <Disclosure.Button>
                      <ReservationCardListDateTitle
                        open={open}
                        date={parseISO(date)}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel>
                      <ReservationCardList
                        listType={'me-confirmed'}
                        schedules={scheduleMeData.dates[date].filter(
                          (x) => !x.is_pending
                        )}
                        embedded={embedded}
                        setOpenReservationsPanel={setOpenReservationsPanel}
                      />
                      <ReservationCardList
                        listType={'me-pending'}
                        schedules={scheduleMeData.dates[date].filter(
                          (x) => x.is_pending
                        )}
                        embedded={embedded}
                        setOpenReservationsPanel={setOpenReservationsPanel}
                      />
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          ))}
        </>
      ) : (
        <NotReservations />
      )}
    </>
  );
}
