import { Disclosure } from '@headlessui/react';
import { parseISO } from 'date-fns';
import Link from 'next/link';
import React, { useMemo } from 'react';
import { IOfficesSchedulesIndexed } from '@gettactic/api';
import { Alert } from '@/components/basic';
import ReservationCardList from '@/components/partial/Cards/ReservationCard/ReservationCardList';
import ReservationCardListDateTitle from '@/components/partial/Cards/ReservationCard/ReservationCardListDateTitle';
import NotReservations from '@/components/dashboard/reservations/NoReservations';

type Props = {
  schedulePendingData: IOfficesSchedulesIndexed | null;
  hasPending: boolean;
  embedded?: boolean;
};

export function TabPending({
  schedulePendingData,
  hasPending,
  embedded = false
}: Props) {
  const dates = useMemo(() => {
    const dates = Object.keys(schedulePendingData?.dates ?? {});
    dates.sort();
    return dates;
  }, [schedulePendingData?.dates]);
  return (
    <>
      <Alert
        variant="tacticAlert"
        canDismiss={false}
        alertTitle=""
        alertMessage=""
        className="mt-2 px-4 py-2"
      >
        <p className="-mt-2 text-base">
          To manage reservations, please use the{' '}
          <Link href={`/admin`}>
            <a className="cursor-pointer text-primary underline">
              new admin dashboard.
            </a>
          </Link>
        </p>
      </Alert>
      {hasPending ? (
        <>
          {schedulePendingData &&
            dates.map((date) => (
              <div className="ml-1 mr-1" key={date}>
                <Disclosure defaultOpen={true}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button>
                        <ReservationCardListDateTitle
                          open={open}
                          date={parseISO(date)}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel>
                        <ReservationCardList
                          listType="admin-pending"
                          schedules={schedulePendingData.dates[date]}
                          embedded={embedded}
                        />
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
            ))}
        </>
      ) : (
        <NotReservations />
      )}
    </>
  );
}
