import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { format } from 'date-fns';
import { useAuthenticated } from '@/lib/api/appUser';
import { useI18n } from '@/hooks/useI18n';

type Props = {
  date: Date;
  open: boolean;
};

export const ReservationCardListDateTitle = ({
  date,
  open
}: Props): JSX.Element => {
  const {
    userContext: { authenticatedUser }
  } = useAuthenticated();
  const { templates } = useI18n(authenticatedUser);
  const dateString = format(date, templates.displayDateWordy);
  return (
    <h3 className="mt-6 inline-flex items-center text-lg font-medium text-gray-600">
      {dateString}
      {open ? (
        <ChevronRightIcon className="h-5 w-5" />
      ) : (
        <ChevronDownIcon className="h-5 w-5" />
      )}
    </h3>
  );
};

export default ReservationCardListDateTitle;
