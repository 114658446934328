import { Card } from '@/components/basic';

export default function NotReservations() {
  return (
    <Card.Container className="mt-4 ml-1 mr-1">
      <Card.Body className="flex content-center justify-between">
        <div className="flex items-center">
          <div className="inline-flex items-center">
            <img
              src="https://res.cloudinary.com/tactic/image/upload/q_auto,f_auto,c_scale,w_115/v1612127352/tactic_web/Dashboard/no_desk_reserved_ya5oyl.svg"
              alt="Computer turned off"
              className="w-28"
            />
          </div>
          <div className="ml-6 flex flex-col">
            <p className="mb-0 font-bold text-secondary">No Reservations</p>
          </div>
        </div>
      </Card.Body>
    </Card.Container>
  );
}
