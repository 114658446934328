import { ScrollArea, Tabs } from '@mantine/core';
import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import {
  AuthenticatedUser,
  IOfficesSchedulesIndexed,
  IQuestionnaire,
  ISchedule
} from '@gettactic/api';
import { canScheduleOthers } from '@/lib/permissions';
import { BannerPendingReservations } from '@/components/dashboard/BannerPendingReservations';
import { TabAdmin } from '@/components/dashboard/reservations/TabAdmin';
import { TabConfirmed } from '@/components/dashboard/reservations/TabConfirmed';
import { TabPending } from '@/components/dashboard/reservations/TabPending';

export type ReservationProps = {
  embedded?: boolean;
  user: AuthenticatedUser;
  officeId: string | null;
  canApprove: boolean;
  scheduleMeData: IOfficesSchedulesIndexed | null;
  schedulePendingData: IOfficesSchedulesIndexed | null;
  scheduleOccupiedData: IOfficesSchedulesIndexed | null;
  schedulerData: IOfficesSchedulesIndexed | null;
  questionnaireData: IQuestionnaire | null;
  weekDate: string;
  setWeekDate: Dispatch<SetStateAction<string>>;
  setOpenReservationsPanel?: React.Dispatch<React.SetStateAction<boolean>>;
};

function countReservations(dates: { [p: string]: ISchedule[] } | undefined) {
  let sum = 0;
  if (!dates) {
    return sum;
  }
  Object.keys(dates).forEach((x) => (sum += dates[x].length));
  return sum;
}

export default function Reservations({
  embedded = false,
  user,
  officeId,
  canApprove,
  schedulePendingData,
  schedulerData,
  scheduleOccupiedData,
  questionnaireData,
  scheduleMeData,
  weekDate,
  setWeekDate,
  setOpenReservationsPanel
}: ReservationProps) {
  const countPending = countReservations(schedulePendingData?.dates);
  const hasPending =
    !!officeId &&
    canApprove &&
    !!schedulePendingData &&
    !!user &&
    countPending > 0;
  const [activeTab, setActiveTab] = useState<string | null>('confirmed');
  const tabs: {
    id: string;
    title: string | (() => JSX.Element);
    heading: string;
    render: () => JSX.Element;
  }[] = useMemo(() => {
    const filterTabs: {
      id: string;
      title: string | (() => JSX.Element);
      heading: string;
      render: () => JSX.Element;
    }[] = [
      {
        id: 'confirmed',
        render: () => (
          <TabConfirmed
            embedded={embedded}
            officeId={officeId}
            scheduleMeData={scheduleMeData}
            questionnaireData={questionnaireData}
            setOpenReservationsPanel={setOpenReservationsPanel}
          />
        ),
        title: 'Confirmed',
        heading: 'My Reservations'
      }
    ];
    if (canApprove) {
      filterTabs.push({
        id: 'pending',
        render: () => (
          <TabPending
            schedulePendingData={schedulePendingData}
            hasPending={hasPending}
            embedded={embedded}
          />
        ),
        title: () => (
          <div>
            Pending{' '}
            {schedulePendingData?.dates ? (
              <span className="ml-auto inline-block rounded-full bg-gray-200 py-0.5 px-3 text-xs text-gray-600">
                {countPending}
              </span>
            ) : null}
          </div>
        ),
        heading: 'Pending Reservations'
      });
    }
    if (canScheduleOthers(user)) {
      filterTabs.push({
        id: 'scheduler',
        render: () => (
          <TabAdmin
            officeId={officeId}
            scheduleOccupiedData={schedulerData}
            embedded={embedded}
          />
        ),
        title: 'Scheduler',
        heading: 'Scheduled for Others'
      });
    }
    if (user && user.permissions.settings) {
      filterTabs.push({
        id: 'adminOrg',
        render: () => (
          <TabAdmin
            officeId={officeId}
            scheduleOccupiedData={scheduleOccupiedData}
            embedded={embedded}
          />
        ),
        title: 'Admin',
        heading: 'Manage Reservations'
      });
    }

    return filterTabs;
  }, [
    user,
    officeId,
    canApprove,
    schedulePendingData,
    scheduleOccupiedData,
    questionnaireData,
    scheduleMeData,
    schedulerData,
    countPending,
    hasPending,
    embedded
  ]);

  const goToPending = () => {
    setActiveTab('pending');
  };

  return (
    <div className="relative">
      <div className="absolute inset-0 pl-4 pr-2">
        {!hasPending ? (
          <BannerPendingReservations
            goToPending={goToPending}
            weekDate={weekDate}
            setWeekDate={setWeekDate}
            canApprove={canApprove}
          />
        ) : null}
        <div className="rounded-lg">
          <h2 className="py-2 text-2xl font-bold text-secondary" role="heading">
            {tabs.map((tabDef) =>
              activeTab === tabDef.id ? tabDef.heading : null
            )}
          </h2>
          <Tabs defaultValue={activeTab} onTabChange={setActiveTab}>
            <Tabs.List>
              {tabs.map((tabDef) => (
                <Tabs.Tab key={tabDef.id} value={tabDef.id}>
                  {typeof tabDef.title === 'function'
                    ? tabDef.title()
                    : tabDef.title}
                </Tabs.Tab>
              ))}
            </Tabs.List>
            {tabs.map(({ render, ...tabDef }) => (
              <Tabs.Panel key={tabDef.id} value={tabDef.id}>
                <ScrollArea
                  style={{ height: 'calc(100vh - 167px)' }}
                  offsetScrollbars
                  className="h-full"
                >
                  {render()}
                </ScrollArea>
              </Tabs.Panel>
            ))}
          </Tabs>
        </div>
      </div>
    </div>
  );
}
