import { ISchedule } from '@gettactic/api';
import { getReserveCardKey } from '@/lib/utils-reserve';
import { useSortedSchedules } from '@/hooks/useSortedSchedules';
import { Card } from '@/components/basic';
import ReservationCardItem from '@/components/partial/Cards/ReservationCard/ReservationCardItem';

type ReservationCardListProps = {
  listType: 'admin-pending' | 'me-pending' | 'me-confirmed' | 'admin-all';
  schedules: ISchedule[] | null;
  embedded: boolean;
  setOpenReservationsPanel?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ReservationCardList = ({
  schedules: unsortedSchedules,
  listType,
  embedded,
  setOpenReservationsPanel
}: ReservationCardListProps): JSX.Element => {
  const schedules = useSortedSchedules(unsortedSchedules);
  return (
    <>
      {listType === 'me-confirmed' && schedules && schedules.length ? (
        <Card.Container className="mt-4 mb-2 mr-2">
          <Card.Body className="flex content-center justify-between">
            <div className="flex items-center">
              <div className="inline-flex items-center">
                <img
                  src="https://res.cloudinary.com/tactic/image/upload/q_auto,f_auto,c_scale,w_115/v1612126939/tactic_web/Dashboard/desk_reserved_ynlfkh.svg"
                  alt="Computer with app on screen"
                  className="w-28"
                />
              </div>
              <div className="ml-6 flex flex-col">
                <p className="mb-0 font-bold text-secondary">
                  Office Space Reserved
                </p>
              </div>
            </div>
          </Card.Body>
        </Card.Container>
      ) : null}
      {(listType === 'me-pending' || listType === 'admin-pending') &&
      schedules &&
      schedules.length ? (
        <Card.Container className="mt-4 mb-2 mr-2">
          <Card.Body className="flex content-center justify-between">
            <div className="flex items-center">
              <div className="inline-flex items-center">
                <img
                  src="https://res.cloudinary.com/tactic/image/upload/q_auto,f_auto,c_scale,w_115/v1612127352/tactic_web/Dashboard/no_desk_reserved_ya5oyl.svg"
                  alt="Computer turned off"
                  className="w-28"
                />
              </div>
              <div className="ml-6 flex flex-col">
                <p className="mb-0 font-bold text-secondary">
                  Pending Reservation
                </p>
              </div>
            </div>
          </Card.Body>
        </Card.Container>
      ) : null}
      {schedules &&
        schedules.map((schedule) => (
          <ReservationCardItem
            schedule={schedule}
            key={getReserveCardKey(schedule)}
            embedded={embedded}
            setOpenReservationsPanel={setOpenReservationsPanel}
          />
        ))}
    </>
  );
};

export default ReservationCardList;
