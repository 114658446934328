import React from 'react';
import { Menu } from '@mantine/core';
import { MapIcon } from '@heroicons/react/outline';
import { PencilIcon, TrashIcon } from '@heroicons/react/solid';
import { FloatingPosition } from '@mantine/core/lib/Floating';

type ReservationActionParams = {
  onEditReservation: () => void;
  currentUserCanEdit: undefined | boolean;
  currentUserCanCancel: undefined | boolean;
  embedded: boolean;
  onViewOnMap: () => void;
  onCancelReservation: () => void;
  children: React.ReactNode;
  position: FloatingPosition;
};

const ReservationActions = ({
  currentUserCanEdit,
  embedded,
  onCancelReservation,
  onEditReservation,
  onViewOnMap,
  currentUserCanCancel,
  children,
  position
}: ReservationActionParams) => (
  <Menu position={position} shadow="md" width={200}>
    <Menu.Target>{children}</Menu.Target>
    <Menu.Dropdown>
      <Menu.Item
        onClick={onEditReservation}
        disabled={!currentUserCanEdit}
        className={!currentUserCanEdit ? 'text-gray-300' : ''}
        icon={<PencilIcon className="h-5 w-5 text-tertiary" />}
      >
        Edit Reservation
      </Menu.Item>
      {/* Temporarily hide until we migrate URL state to context due to Teams iframe restriction */}
      {embedded ? null : (
        <Menu.Item
          onClick={onViewOnMap}
          icon={<MapIcon className="h-5 w-5 text-tertiary" />}
        >
          View on Map
        </Menu.Item>
      )}
      {currentUserCanCancel ? (
        <Menu.Item
          onClick={onCancelReservation}
          icon={<TrashIcon className="h-5 w-5 text-tertiary" />}
        >
          Cancel Reservation
        </Menu.Item>
      ) : null}
    </Menu.Dropdown>
  </Menu>
);

export default ReservationActions;
