import { TextEditorContent } from '@/components/basic';
import { Tooltip } from '@/components/basic';
import ReservationActions from '@/components/partial/Cards/ReservationCard/ReservationActions';
import { ReservationCardRespondPresence } from '@/components/partial/Cards/ReservationCard/ReservationCardRespondPresence';
import {
  USER_PHOTO_SIZES,
  UserPhotoCard
} from '@/components/partial/Cards/UserPhotoCard';
import { ReservationCancel } from '@/components/reserve/ReservationCancel/ReservationCancel';
import ReserveCheckInButton from '@/components/reserve/ReserveCheckin/ReserveCheckInButton';
import ReserveEditModal from '@/components/reserve/ReserveEditModal';
import { useResourceParents } from '@/hooks/useResourceParents';
import { api } from '@/lib/api/api';
import { useAuthenticated } from '@/lib/api/appUser';
import { canScheduleOthers } from '@/lib/permissions';
import { formatStartEndHours, scheduleResourceStyling } from '@/lib/utils';
import { getDistanceToNow } from '@/lib/utils';
import { findCheckinTargetSlot } from '@/lib/utils-reserve';
import { IReservation } from '@/types/Reservation';
import {
  ISchedule,
  ISlot,
  PRESENCE_STATUS_ACCEPTED,
  PRESENCE_STATUS_PENDING,
  PRESENCE_STATUS_REJECTED,
  PRESENCE_TYPE_IN_PERSON,
  PRESENCE_TYPE_REMOTE,
  isParking
} from '@gettactic/api';
import {
  BanIcon,
  CheckIcon,
  DocumentTextIcon,
  DotsVerticalIcon,
  MapIcon,
  OfficeBuildingIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
  VideoCameraIcon
} from '@heroicons/react/outline';
import { ActionIcon, Menu } from '@mantine/core';
import { ScrollArea } from '@mantine/core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { format, isPast, parseISO } from 'date-fns';
import { useRouter } from 'next/router';
import React, { useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { IoIosRepeat } from 'react-icons/io';

function duplicatesExist(arr: ISlot[]) {
  const slotUserIds = arr.map((obj) => obj.user.id);
  return new Set(slotUserIds).size !== arr.length;
}

// Temporary function that allows us to remove duplicate user slots
// from meeting room schedules. This is a temporary fix that only needs to exist
// until the API is updated to not return duplicates.
function removeDuplicateSlots(slots: ISlot[]): ISlot[] {
  const uniqueSlots: { [key: string]: ISlot } = {};

  slots.forEach((slot) => {
    if (!uniqueSlots[slot.user.id]) {
      uniqueSlots[slot.user.id] = slot;
    }
  });

  return Object.values(uniqueSlots);
}

function isRoom(type: string): boolean {
  return type === 'meeting_room';
}

function isWorkspace(type: string): boolean {
  return type === 'desk' || type === 'workspace';
}

interface ReservationCardItemProps {
  schedule: ISchedule;
  embedded?: boolean;
  setOpenReservationsPanel?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReservationCardItem: React.FC<ReservationCardItemProps> = ({
  schedule,
  embedded = false,
  setOpenReservationsPanel
}) => {
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    userContext: { authenticatedUser, currentOffice }
  } = useAuthenticated();
  const router = useRouter();
  const endDate = new Date(schedule.end.replace('Z', ''));
  const scheduleInThePast = isPast(endDate);
  const targetSlot = findCheckinTargetSlot(
    schedule,
    authenticatedUser.user?.id ?? ''
  );
  // If multiple slots on single schedule, prioritize desk/workspace over parking spaces for display
  const prioritySlot = schedule.slots.sort((s) =>
    s.resource.type === 'desk' || s.resource.type === 'workspace' ? -1 : 1
  )[0];
  const resourceChainQuery = useResourceParents(prioritySlot.resource);
  const parking = schedule.slots.find((x) => isParking(x.resource));
  const scheduleStyle = scheduleResourceStyling(prioritySlot.resource.type);
  const userSlot: ISlot | undefined = schedule.slots.find(
    (s) => s.user.id === authenticatedUser.user?.id
  );
  const userMultipleResources = duplicatesExist(schedule.slots);
  const queryClient = useQueryClient();

  // If the remote_checkin setting is false, we need to hide the check-in / check-out buttons
  // TODO: We will need to decide what to do with this logic as we add additional resource types
  const office = authenticatedUser?.offices?.byId[currentOffice.id];
  const officeHasRoomEnableCheckIn = office?.meeting_room_remote_checkin;
  const officeHasWorkspaceEnableCheckIn = office?.workspace_remote_checkin;

  const checkinMutation = useMutation(
    async ({
      resourceId,
      scheduleId,
      slotId
    }: { resourceId: string; scheduleId: string; slotId: string }) => {
      setLoading(true);
      const res = await api.client.schedules.checkin(
        resourceId,
        scheduleId,
        slotId
      );
      queryClient.invalidateQueries(['schedules']);
    },
    {
      onSuccess: (result) => {
        setLoading(false);
        toast.success("You're checked in");
      },
      onError: (result) => {
        setLoading(false);
        setLoading(false);
        toast.error(`Sorry we couldn't complete the check in`);
      }
    }
  );
  const checkoutMutation = useMutation(
    async ({
      resourceId,
      scheduleId,
      slotId
    }: { resourceId: string; scheduleId: string; slotId: string }) => {
      setLoading(true);
      const res = await api.client.schedules.checkout(
        resourceId,
        scheduleId,
        slotId
      );
      queryClient.invalidateQueries(['schedules']);
    },
    {
      onSuccess: (result) => {
        setLoading(false);
        toast.success("You've checked out");
      },
      onError: (result) => {
        setLoading(false);
        toast.error(`Sorry we couldn't complete the checkout`);
      }
    }
  );

  const onCheckin = async (
    resourceId: string,
    scheduleId: string,
    slotId: string
  ) => {
    checkinMutation.mutate({ resourceId, scheduleId, slotId });
  };
  const onCheckout = async (
    resourceId: string,
    scheduleId: string,
    slotId: string
  ) => {
    checkoutMutation.mutate({ resourceId, scheduleId, slotId });
  };
  const dateFormatted = format(
    parseISO(schedule.start.replace('Z', '')),
    'eeee, d MMM'
  );

  // See note on removeDuplicateSlots() function. This is temporary.
  // Once need is gone, we can move back to schedule.slots.map()
  const uniqueMeetingRoomSlots = removeDuplicateSlots(schedule.slots);

  const goToMap = (resourceId: string, officeId: string, date: string) => {
    const dateStr = date.split('T')[0];
    if (embedded && setOpenReservationsPanel) {
      router.push(
        `/microsoft-teams/home#resource=${resourceId}&office=${officeId}&date=${dateStr}`,
        undefined,
        {
          shallow: true
        }
      );
      // This only exists in Microsoft Teams
      setOpenReservationsPanel((x) => !x);
    } else {
      router.push(
        `/map#resource=${resourceId}&office=${officeId}&date=${dateStr}`
      );
    }
  };

  const pendingApproval: boolean = schedule.is_pending;
  const earliestCheckInTime: string =
    schedule.slots[0]?.earliest_start_check_in_time ?? '';
  const endTime: string = schedule.slots[0]?.end;
  const scheduleId: string = schedule.id;
  const resourceId: string = schedule.resource.id;
  const renderCheckIn = useRef(false);

  const reservationObject: IReservation = useMemo(() => {
    if (targetSlot) {
      const allowCheckIn =
        !!targetSlot?.capabilities?.can_respond_presence_check_in;
      const allowCheckOut =
        !!targetSlot?.capabilities?.can_respond_presence_check_out;
      const canRespondCancel =
        !!targetSlot?.capabilities?.can_respond_cancelled;
      //@ts-ignore
      const hasCheckedIn = !!targetSlot?.has_checked_in;
      //@ts-ignore
      const hasCheckedOut = !!targetSlot?.has_checked_out;
      const checkInTime = !!targetSlot?.is_check_in_time;
      const checkOutTime = targetSlot?.check_out_time;
      const checkInCheckOutDisabled: boolean = !allowCheckIn && !allowCheckOut;
      const canRespondPresence =
        !!targetSlot?.capabilities?.can_respond_presence;
      const canRespondPresenceRemote =
        !!targetSlot?.capabilities?.can_respond_presence_remote;
      const canRespondPresenceInPerson =
        !!targetSlot?.capabilities?.can_respond_presence_in_person;
      const canEdit = targetSlot?.capabilities?.can_edit;
      renderCheckIn.current = !checkInCheckOutDisabled;
      return {
        slotId: targetSlot?.id,
        allowCheckIn,
        allowCheckOut,
        canEdit,
        canRespondCancel,
        hasCheckedIn,
        hasCheckedOut,
        checkInTime,
        checkInCheckOutDisabled,
        checkOutTime,
        canRespondPresence,
        canRespondPresenceRemote,
        canRespondPresenceInPerson
      };
    }
    return {};
  }, [targetSlot]);

  const currentUserCanEdit =
    reservationObject?.canEdit &&
    !scheduleInThePast &&
    (schedule.organizer_id === authenticatedUser?.user?.id ||
      canScheduleOthers(authenticatedUser) ||
      (prioritySlot.resource.type !== 'meeting_room' &&
        reservationObject?.canRespondCancel));

  return (
    <>
      <li
        key={schedule.id}
        className="grid min-h-[275px] grid-cols-12 rounded-lg bg-white drop-shadow-[0_1px_3px_rgba(0,0,0,0.25)] md:mb-2 md:mr-2 md:min-h-[175px]"
      >
        <div
          className={`${scheduleStyle.style} relative col-span-12 flex h-full w-full items-center justify-center overflow-hidden rounded-l-lg md:col-span-3`}
        >
          <img
            src={scheduleStyle.image}
            alt={scheduleStyle.alt}
            className="h-[175px] object-cover"
          />
          <span
            title={
              schedule.recurring_schedule_id
                ? 'Recurring reservation ' + dateFormatted
                : dateFormatted
            }
            className="absolute top-2 left-2 flex items-center space-x-1 rounded-2xl border border-white bg-green-600 px-3 py-1 text-sm text-white font-semibold"
          >
            <span>{getDistanceToNow(schedule.start)}</span>
            {schedule.recurring_schedule_id ? (
              <IoIosRepeat className="h-5 w-5" />
            ) : null}
          </span>
        </div>
        <div className="col-span-12 rounded-r-lg md:col-span-9">
          <div className="h-full p-2">
            <div className="flex h-full flex-col justify-between">
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <h3 className="text-xl font-bold text-secondary">
                    {isRoom(prioritySlot.resource.type)
                      ? schedule.title
                      : prioritySlot.resource.name}
                  </h3>
                  <span className="text-primary">
                    {formatStartEndHours(schedule.start, schedule.end).join(
                      ' - '
                    )}
                  </span>
                  {isRoom(prioritySlot.resource.type) ? (
                    <div className="flex">
                      <span className="text-sm text-tertiary">
                        {prioritySlot.resource.name}
                      </span>
                      {schedule.description ? (
                        <Menu
                          shadow="md"
                          width={350}
                          position="bottom"
                          withinPortal
                        >
                          <Menu.Target>
                            <DocumentTextIcon className="ml-1 h-5 w-5 cursor-pointer text-tertiary" />
                          </Menu.Target>
                          <Menu.Dropdown>
                            <TextEditorContent
                              value={schedule.description}
                              className="border-none p-2"
                            />
                          </Menu.Dropdown>
                        </Menu>
                      ) : null}
                    </div>
                  ) : null}
                  <span className="text-sm text-tertiary">
                    {resourceChainQuery.fullName}
                  </span>
                  {isRoom(prioritySlot.resource.type) ? (
                    <Menu
                      width={200}
                      shadow="md"
                      position="bottom-start"
                      withinPortal
                    >
                      <Menu.Target>
                        <div className="mt-1 flex cursor-pointer items-center -space-x-1 overflow-hidden">
                          {uniqueMeetingRoomSlots.slice(0, 5).map((slot) => (
                            <UserPhotoCard
                              key={`array_${slot.id}`}
                              size={USER_PHOTO_SIZES.ROUNDED_6_6}
                              name={slot.user.name}
                              profile_photo_url={
                                slot.user.profile_photo_url
                                  ? slot.user.profile_photo_url
                                  : null
                              }
                              className="ring-2 ring-white"
                            />
                          ))}
                          {uniqueMeetingRoomSlots.length > 5 ? (
                            <div className="rounded-full bg-primary-bg p-1.5">
                              <PlusIcon className="h-4 w-4" />
                            </div>
                          ) : null}
                        </div>
                      </Menu.Target>
                      <Menu.Dropdown>
                        <ScrollArea
                          style={{ height: '200px' }}
                          offsetScrollbars
                        >
                          {uniqueMeetingRoomSlots.map((slot) => (
                            <Menu.Item
                              key={`dropdown_${slot.id}`}
                              className="p-2"
                            >
                              <div className="grid grid-cols-12 items-center">
                                <UserPhotoCard
                                  key={slot.id}
                                  size={USER_PHOTO_SIZES.ROUNDED_6_6}
                                  name={slot.user.name}
                                  profile_photo_url={
                                    slot.user.profile_photo_url
                                      ? slot.user.profile_photo_url
                                      : null
                                  }
                                  className="col-span-2 ring-2 ring-white"
                                />
                                <span className="col-span-8 truncate pl-1 text-tertiary">
                                  {slot.user.name}
                                </span>
                                <div className="col-span-2">
                                  {slot.presence_status ===
                                  PRESENCE_STATUS_REJECTED ? (
                                    <BanIcon className="h-5 w-5 text-red-500" />
                                  ) : null}
                                  {slot.presence_status ===
                                  PRESENCE_STATUS_PENDING ? (
                                    <QuestionMarkCircleIcon className="h-5 w-5 text-gray-600" />
                                  ) : null}
                                  {slot.presence_status ===
                                    PRESENCE_STATUS_ACCEPTED &&
                                  slot.presence_type ===
                                    PRESENCE_TYPE_IN_PERSON ? (
                                    <OfficeBuildingIcon className="h-5 w-5 text-green-500" />
                                  ) : null}
                                  {slot.presence_status ===
                                    PRESENCE_STATUS_ACCEPTED &&
                                  slot.presence_type ===
                                    PRESENCE_TYPE_REMOTE ? (
                                    <VideoCameraIcon className="h-5 w-5 text-green-500" />
                                  ) : null}
                                  {slot.presence_status ===
                                    PRESENCE_STATUS_ACCEPTED &&
                                  slot.presence_type === null ? (
                                    <CheckIcon className="h-5 w-5 text-green-500" />
                                  ) : null}
                                </div>
                              </div>
                            </Menu.Item>
                          ))}
                        </ScrollArea>
                      </Menu.Dropdown>
                    </Menu>
                  ) : null}
                </div>
                <div>
                  <ReservationActions
                    position="bottom-end"
                    onEditReservation={() => {
                      if (!currentUserCanEdit) {
                        return;
                      }
                      setEditModal(true);
                    }}
                    currentUserCanEdit={currentUserCanEdit}
                    currentUserCanCancel={
                      targetSlot?.capabilities.can_respond_cancelled
                    }
                    embedded={embedded}
                    onViewOnMap={() =>
                      goToMap(
                        prioritySlot.resource.id,
                        prioritySlot.resource.office_id,
                        schedule.start
                      )
                    }
                    onCancelReservation={() => setConfirmDialog(true)}
                  >
                    <ActionIcon>
                      <DotsVerticalIcon className="h-6 w-6 cursor-pointer text-tertiary" />
                    </ActionIcon>
                  </ReservationActions>
                </div>
              </div>
              <div className="mt-1 flex justify-between">
                {isRoom(prioritySlot.resource.type) &&
                userSlot &&
                schedule.slots.length > 1 &&
                !userMultipleResources ? (
                  <ReservationCardRespondPresence
                    userSlot={userSlot}
                    schedule={schedule}
                  />
                ) : null}
                {parking ? (
                  <div className="">
                    <span
                      title={parking.resource.name}
                      className="inline-flex items-center rounded-full border-2 border-tertiary px-[0.45rem] py-0.5 text-sm font-bold text-tertiary"
                    >
                      P
                    </span>
                  </div>
                ) : null}

                {renderCheckIn ? (
                  <>
                    <div />
                    <div>
                      <>
                        {(isWorkspace(prioritySlot.resource.type) &&
                          !officeHasWorkspaceEnableCheckIn &&
                          !schedule.is_pending) ||
                        (isRoom(prioritySlot.resource.type) &&
                          !officeHasRoomEnableCheckIn &&
                          !schedule.is_pending) ? (
                          <Tooltip position="top">
                            Your office has disabled remote check-in. Please
                            check in via QR code or via NFC scan.
                          </Tooltip>
                        ) : null}

                        {(isWorkspace(prioritySlot.resource.type) &&
                          officeHasWorkspaceEnableCheckIn &&
                          !schedule.is_pending) ||
                        (isRoom(prioritySlot.resource.type) &&
                          officeHasRoomEnableCheckIn &&
                          !schedule.is_pending) ? (
                          <ReserveCheckInButton
                            loading={loading}
                            checkinResource={onCheckin}
                            checkoutResource={onCheckout}
                            isRBFlag
                            earliestCheckInTime={earliestCheckInTime}
                            pendingApproval={pendingApproval}
                            endTime={endTime}
                            scheduleId={scheduleId}
                            resourceId={resourceId}
                            {...reservationObject}
                          />
                        ) : null}

                        {schedule.is_pending ? (
                          <span className="rounded-full bg-primary-bg py-1 px-2 text-xs text-primary">
                            Pending Approval
                          </span>
                        ) : null}
                      </>
                      <div className="mx-2 border-r" />
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </li>
      <ReservationCancel
        schedules={[schedule]}
        setOpen={setConfirmDialog}
        open={confirmDialog}
      />
      <ReserveEditModal
        editModal={editModal}
        toggleModal={() => setEditModal(!editModal)}
        schedule={schedule}
      />
    </>
  );
};

export default ReservationCardItem;
