import { getResourceChain } from '@/lib/utils';
import { IResource, Office } from '@gettactic/api';
import { useOfficesQuery } from '@gettactic/hooks';
import { useQuery } from '@tanstack/react-query';

function buildFullName(chain: IResource[], office: Office) {
  const chainNames = chain.map((c) => c?.name);
  const chainParentsWithOffice = [...chainNames, office.name];
  return chainParentsWithOffice.join(' ∙ ');
}

export function useResourceParents(resource: IResource) {
  const resourceChainQuery = useQuery(
    ['useResourceParents', resource.parent_id],
    async () => getResourceChain(resource.parent_id)
  );
  const offices = useOfficesQuery();
  const isLoading = offices.isLoading || resourceChainQuery.isLoading;
  const chain = resourceChainQuery.data;
  const officeId = resource.office_id ?? '';
  const office = offices.data?.byId?.[officeId];
  return {
    isLoading,
    fullName: chain && office ? buildFullName(chain, office) : '',
    chain,
    office,
    floor: chain && chain.length > 0 ? chain[chain.length - 1] : undefined
  };
}
