import { ConfirmDialog } from '@/components/basic/ConfirmDialog/ConfirmDialog';

export interface Props {
  title?: string;
  subTitle?: string | JSX.Element;
  confirmText?: string;
  cancelText?: string;
  open: boolean;
  confirmAction: () => Promise<void>;
  cancelAction?: () => void;
}

export function ReservationCheckout({
  title = 'Confirm Checkout',
  open,
  confirmAction,
  cancelAction = () => {},
  subTitle,
  confirmText = 'Yes',
  cancelText = 'No'
}: Props) {
  return (
    <ConfirmDialog
      title={title}
      open={open}
      noFlex
      onConfirm={confirmAction}
      onClose={cancelAction}
      type="cancel"
      confirmText={confirmText}
      cancelText={cancelText}
    >
      {subTitle}
    </ConfirmDialog>
  );
}
