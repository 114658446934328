import {
  BanIcon,
  ChevronDownIcon,
  OfficeBuildingIcon,
  QuestionMarkCircleIcon,
  VideoCameraIcon
} from '@heroicons/react/outline';
import { Group, Select, Text } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import {
  ComponentPropsWithoutRef,
  forwardRef,
  useEffect,
  useState
} from 'react';
import {
  ISchedule,
  ISlot,
  PRESENCE_STATUS_ACCEPTED,
  PRESENCE_STATUS_PENDING,
  PRESENCE_STATUS_REJECTED,
  PRESENCE_TYPE_IN_PERSON,
  PRESENCE_TYPE_REMOTE,
  PresenceStatus,
  PresenceType
} from '@gettactic/api';
import { api } from '@/lib/api/api';
import { classNames } from '@/lib/classNames';

export type Props = {
  schedule: ISchedule;
  userSlot: ISlot;
  remoteEnabled?: boolean;
};

interface ItemProps extends ComponentPropsWithoutRef<'div'> {
  icon: JSX.Element;
  label: string;
  description: string;
}

const ScheduleCardRSVPSelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ icon, label, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <div className={classNames('flex items-center gap-2 text-tertiary ')}>
          {icon}
          <Text size="sm">{label}</Text>
        </div>
      </Group>
    </div>
  )
);

function getValue(type: PresenceType | null, status: PresenceStatus) {
  if (status === PRESENCE_STATUS_ACCEPTED) {
    return type ?? PRESENCE_STATUS_ACCEPTED;
  }
  return status;
}

export function ReservationCardRespondPresence({
  schedule,
  userSlot,
  remoteEnabled
}: Props) {
  const [presenceStatus, setPresenceStatus] = useState<PresenceStatus>(
    userSlot.presence_status
  );
  const [presenceType, setPresenceType] = useState<PresenceType | null>(
    userSlot.presence_type
  );
  useEffect(() => {
    setPresenceStatus(userSlot.presence_status);
    setPresenceType(userSlot.presence_type);
  }, [userSlot]);

  const currentValue = getValue(presenceType, presenceStatus);
  const queryClient = useQueryClient();
  const onApprovalPresence = async (
    status: PresenceStatus,
    presenceType: PresenceType | null
  ) => {
    await api.client.schedules.presence(
      schedule.id,
      userSlot.id,
      status,
      presenceType ?? undefined
    );
    await queryClient.invalidateQueries(['schedules']);
  };

  return (
    <>
      <Select
        value={currentValue}
        withinPortal
        aria-label="rsvp"
        placeholder="RSVP"
        className="rounded-lg text-green-800 w-full text-center lg:w-[120px]"
        rightSection={<ChevronDownIcon className="h-3 w-3" />}
        rightSectionWidth={40}
        itemComponent={ScheduleCardRSVPSelectItem}
        classNames={{
          wrapper:
            'border border-tacticGrayBlue-400 flex flex-1 justify-center rounded-full',
          input:
            'lg:border bg-transparent text-center placeholder:text-tacticGrayBlue-700 quicksand text-bold',
          rightSection:
            'left-[58%] top-[58%] transform translate-y-[-55%] text-tacticGrayBlue-700'
        }}
        onChange={(value) => {
          if (
            value === PRESENCE_TYPE_IN_PERSON ||
            value === PRESENCE_TYPE_REMOTE
          ) {
            setPresenceStatus(PRESENCE_STATUS_ACCEPTED);
            setPresenceType(value);
            onApprovalPresence(PRESENCE_STATUS_ACCEPTED, value as PresenceType);
          } else {
            setPresenceStatus(value as PresenceStatus);
            setPresenceType(null);
            onApprovalPresence(value as PresenceStatus, null);
          }
        }}
        styles={{
          rightSection: { pointerEvents: 'none' },
          input: {
            border: 'none !important',
            width: '120px',
            paddingTop: '0px!important',
            paddingBottom: '0px!important',
            height: '40px',
            minHeight: '40px',
            borderRadius: '20px!important'
          },
          item: {
            '&[data-selected]': {
              backgroundColor: '#d0e7fb',
              '&:hover': {
                backgroundColor: '#d0e7fb'
              }
            }
          },
          wrapper: { height: '40px' }
        }}
        data={[
          {
            value: PRESENCE_TYPE_IN_PERSON,
            label: 'Office',
            icon: <OfficeBuildingIcon className="h-5 w-5 text-green-500" />
          },
          {
            value: PRESENCE_TYPE_REMOTE,
            label: 'Virtual',
            icon: <VideoCameraIcon className="h-5 w-5 text-green-500" />,
            disabled: !remoteEnabled
          },
          {
            value: PRESENCE_STATUS_PENDING,
            label: 'Maybe',
            icon: <QuestionMarkCircleIcon className="h-5 w-5 text-gray-600" />
          },
          {
            value: PRESENCE_STATUS_REJECTED,
            label: 'Decline',
            icon: <BanIcon className="h-5 w-5 text-red-500" />
          }
        ].filter((item) => !item.disabled)}
      />
    </>
  );
}
