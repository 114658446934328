import React from 'react';
import { SideDrawer } from '@/components/partial/SideDrawer/SideDrawer';
import Reservations, {
  ReservationProps
} from '@/components/dashboard/Reservations';

type Props = ReservationProps & {
  embedded?: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ReservationsMobilePanel({
  embedded = false,
  open,
  setOpen,
  ...reservationProps
}: Props) {
  return (
    <SideDrawer open={open} setOpen={setOpen} title={''}>
      <div className="relative -mx-4 -my-6 flex-1">
        <Reservations
          embedded={embedded}
          setOpenReservationsPanel={setOpen}
          {...reservationProps}
        />
      </div>
    </SideDrawer>
  );
}
