import Link from 'next/link';
import React from 'react';
import { IoArrowForward } from 'react-icons/io5';
import { useAuthenticated } from '@/lib/api/appUser';
import { isReservationSurveyEnabled } from '@/lib/featureFlags';

export const QuestionnaireReminder = ({
  embedded = false,
  is_passed,
  can_update,
  maximum_minutes_grace_period
}: {
  embedded?: boolean;
  is_passed: boolean;
  can_update: boolean;
  maximum_minutes_grace_period: number;
}) => {
  const {
    userContext: { authenticatedUser }
  } = useAuthenticated();
  const reservationSurvey = isReservationSurveyEnabled(authenticatedUser);

  return (
    <>
      {!can_update || !is_passed ? (
        <Link href="/questionnaire">
          <a
            target={embedded ? '_blank' : undefined}
            rel={embedded ? 'noopener noreferrer' : undefined}
          >
            <div className="border-1 mt-6 flex cursor-pointer flex-col rounded-lg border-yellow-300 bg-yellow-50 px-4 py-2">
              <div className="flex items-center">
                <div className="shrink-0">
                  <svg
                    className="h-8 w-8 text-yellow-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <h3 className="ml-3 text-lg font-medium text-yellow-800">
                  {reservationSurvey
                    ? 'Reservation Survey'
                    : 'Health and Safety Questionnaire'}
                </h3>
              </div>
              <div className="flex flex-col">
                <div className="mt-1 text-base text-yellow-800">
                  <p>
                    {can_update && (
                      <>
                        Looks like you've{' '}
                        {reservationSurvey
                          ? 'answered a question incorrectly'
                          : 'responded that you have found yourself at risk.'}{' '}
                        You can still update your answers during the grace
                        period, which ends in{' '}
                        {maximum_minutes_grace_period > 60
                          ? `${(maximum_minutes_grace_period / 60).toFixed(0)} hours`
                          : `${maximum_minutes_grace_period} minutes`}
                      </>
                    )}
                    {!can_update && (
                      <>
                        Looks like you're planning on going into the office
                        soon. Please fill out your{' '}
                        {reservationSurvey
                          ? 'reservation survey'
                          : 'health and safety questionnaire'}{' '}
                        first!
                      </>
                    )}{' '}
                    <IoArrowForward className="inline h-4 w-4" />
                  </p>
                </div>
              </div>
            </div>
          </a>
        </Link>
      ) : null}
    </>
  );
};

export default QuestionnaireReminder;
